import React, { useEffect, useState } from 'react'
import Breadcrumb from '../components/Breadcrumb';

import upload from '../assets/upload.svg';


import youtube from '../assets/socialMedia/youtube.svg';
import instagram from '../assets/socialMedia/instagram.svg';
import tikTok from '../assets/socialMedia/tikTok.svg';
import snapchat from '../assets/socialMedia/snapchat.svg';
import x from '../assets/socialMedia/x.svg';

import eye from '../assets/eye.svg';
import grayEye from '../assets/grayEye.svg';
import editIcon from '../assets/editIcon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../helpers/api';
import Header from '../components/Header';
import { decryption, encryption } from '../cipher/cipher';


const InfluencerView = () => {

    let navigate = useNavigate();

    const [search, setSearch] = useState('');

    let [info, setInfo] = useState({});

    let { id } = useParams();


    useEffect(() => {
        const handleInfluencerDetails = async () => {
            try {
                let encryptData = await encryption({ userId: localStorage.getItem('userId'), id: id })
                let response = await api.post('/influencer', { data: encryptData });

                if (response.status == 603) {
                    localStorage.clear();
                    navigate('/login')
                }

                let decryptData = await decryption(response.data);
                response.data = decryptData;

                setInfo(response?.data)
            }
            catch (err) {
                console.log(err);
            }
        }

        handleInfluencerDetails();
    }, [])

    return (
        <>
            <Header setSearch={setSearch} />
            <main className='mt-[145px] xl:mt-[110px] 2xl:mt-[80px] pt-[30px] px-[80px] h-[calc(100vh-145px)] xl:h-[calc(100vh-110px)] 2xl:h-[calc(100vh-80px)] w-full'>
                <div className='flex justify-center items-center'>
                    <div className='mb-16 relative'>
                        <Breadcrumb title={"Influencer profile"} currentPage={[{ label: "Influencer profile", class: "active", }]} />
                        <div className='flex justify-between items-start gap-20 mb-8'>
                            <div>
                                <div className='font-bold text-3xl mb-4'>About</div>
                                <div className='my-4'>Gender: {info?.gender == 0 ? 'Male' : 'Female'}</div>
                                <div className='my-4'>Category: {info?.categoryName}</div>
                                <div className='my-4'>Interests: {info?.interestName}</div>
                                <div className='my-4'>Country: {info?.countryName}</div>
                                <div className='my-4'>City: {info?.cityName}</div>
                            </div>
                            <div>
                                <div className='font-bold text-3xl mb-4'>Contact details</div>
                                <div className='my-4'>Full name: {info?.fullName}</div>
                                <div className='my-4'>Phone number: +965 {info?.phone}</div>
                                <div className='my-4'>Email address: {info?.email}</div>
                            </div>
                            <div>
                                <img src={info?.imageUrl ? global.config.API_URL + info?.imageUrl : upload} alt='influencer-image' className={`w-[161px] h-[161px] object-cover ${info?.imageUrl ? 'rounded-2xl' : ''}`} />
                            </div>
                        </div>
                        <div className='w-[100%]'>
                            <div className='font-bold text-4xl mt-16'>Social media accounts</div>
                            <div className='mt-4'>Instagram</div>
                            <div className='flex items-center justify-between w-full mb-4 bg-[#FAFAFA] p-2 rounded-3xl'>
                                <img src={instagram} />
                                <div>{(info?.instagramCount) ? info?.instagramCount : 'Not available'}</div>
                                {
                                    (info?.instagram) ?
                                        <img src={eye} className='cursor-pointer' onClick={() => window.open(info?.instagram, '_blank')} />
                                        :
                                        <img src={grayEye} className='cursor-default' />
                                }
                            </div>
                            <div className='mt-4'>TikTok</div>
                            <div className='flex items-center justify-between w-full  mb-4 bg-[#FAFAFA] p-2 rounded-3xl'>
                                <img src={tikTok} />
                                <div>{(info?.tiktokCount) ? info?.tiktokCount : 'Not available'}</div>
                                {
                                    (info?.tiktok) ?
                                        <img src={eye} className='cursor-pointer' onClick={() => window.open(info?.tiktok, '_blank')} />
                                        :
                                        <img src={grayEye} className='cursor-default' />
                                }
                            </div>
                            <div className='mt-4'>Snapchat</div>
                            <div className='flex items-center justify-between w-full  mb-4 bg-[#FAFAFA] p-2 rounded-3xl'>
                                <img src={snapchat} />
                                <div>{(info?.snapchatCount) ? info?.snapchatCount : 'Not available'}</div>
                                {
                                    (info?.snapchat) ?
                                        <img src={eye} className='cursor-pointer' onClick={() => window.open(info?.snapchat, '_blank')} />
                                        :
                                        <img src={grayEye} className='cursor-default' />
                                }
                            </div>
                            <div className='mt-4'>X</div>
                            <div className='flex items-center justify-between w-full  mb-4 bg-[#FAFAFA] p-2 rounded-3xl'>
                                <img src={x} />
                                <div>{(info?.xCount) ? info?.xCount : 'Not available'}</div>
                                {
                                    (info?.x) ?
                                        <img src={eye} className='cursor-pointer' onClick={() => window.open(info?.x, '_blank')} />
                                        :
                                        <img src={grayEye} className='cursor-default' />
                                }
                            </div>
                            <div className='mt-4'>Youtube</div>
                            <div className='flex items-center justify-between w-full  mb-4 bg-[#FAFAFA] p-2 rounded-3xl'>
                                <img src={youtube} />
                                <div>{(info?.youtubeCount) ? info?.youtubeCount : 'Not available'}</div>
                                {
                                    (info?.youtube) ?
                                        <img src={eye} className='cursor-pointer' onClick={() => window.open(info?.youtube, '_blank')} />
                                        :
                                        <img src={grayEye} className='cursor-default' />
                                }
                            </div>
                        </div>
                        <img src={editIcon} className='top-4 right-0 absolute h-7 w-7 cursor-pointer' onClick={() => navigate('/influencer/edit/' + info?.id)} />

                    </div>
                </div>
            </main>
        </>
    )
}

export default InfluencerView;
