import React, { useState, useContext } from 'react';

// logo

import logo from '../assets/logo.svg';
import humburger from '../assets/humburger.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import search from '../assets/search.svg';

import Modal from 'react-awesome-modal';
import CompanyForm from './CompanyForm';
import ListForm from './ListForm';

import { CanvasContext } from '../context/MainContext'


const Header = ({ setSearch }) => {

    let { setRightBar } = useContext(CanvasContext);

    const privileges = JSON.parse(localStorage.getItem('privileges')) || [];

    let navigate = useNavigate();
    let location = useLocation();

    const [customerModelStatus, setCustomerModelStatus] = useState(false);
    const [listModelStatus, setListModelStatus] = useState(false);


    return (
        <>
            <nav className="bg-white border-b border-['#EAEAEA'] border-b-1 fixed top-0 left-0 right-0 z-10">
                <div className="w-full flex flex-wrap items-center justify-between mx-auto py-4 px-12">

                    {/* Logo */}
                    <div className='flex gap-12'>
                        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                            <img src={logo} alt="evewaii-logo" />
                        </a>

                        {
                            privileges.includes('influencerAdd') && location.pathname !== '/influencer/add' && !(location.pathname.startsWith('/influencer/edit/')) &&
                            < button type="button" className="text-white bg-[#F15625] border border-[#000000] hover:bg-[#F15625] font-medium rounded-lg text-sm px-4 py-3" onClick={() => navigate('/influencer/add')}>Add Influencer</button>
                        }
                    </div>
                    <div className='flex gap-6'>

                        {/* Navlinks */}
                        <ul className="flex flex-col p-3 md:p-0 mt-4 font-normal border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white ">
                            <li>
                                <a href={'/'} className="block py-2 px-0 text-black cursor-pointer">Home</a>
                            </li>
                            {
                                privileges.includes('customer') &&
                                <li><a href={'/customers'} className="block py-2 px-0 text-black cursor-pointer">Customers</a></li>
                            }
                            {
                                privileges.includes('list') &&
                                <li>
                                    <a href={'/lists'} className="block py-2 px-0 text-black cursor-pointer">Lists</a>
                                </li>
                            }
                            {
                                privileges.includes('user') &&
                                <li>
                                    <a href={'/users'} className="block py-2 px-0 text-black cursor-pointer">Users</a>
                                </li>
                            }
                            {
                                privileges.includes('cms') &&
                                <li>
                                    <a href={'/CMS'} className="block py-2 px-0 text-black cursor-pointer">CMS</a>
                                </li>
                            }
                        </ul>

                        {/* Search bar */}
                        <div className='w-[350px]'>
                            <div className="relative text-gray-600">
                                <input type="text" name="serch" placeholder="Search..." className="bg-white border w-full placeholder-black border-[#000000] h-10 px-5 pr-10 rounded-full text-sm focus:outline-none" onChange={(e) => setSearch(e.target.value)} />
                                <button type="submit" className="absolute right-0 top-0 mt-3 mr-4">
                                    <svg className="h-4 w-4 fill-current text-black" viewBox="0 0 56.966 56.966" width="512px" height="512px">
                                        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        {/* CTA buttons */}
                        <div className='flex gap-3'>
                            {
                                privileges.includes('customerAdd') &&
                                <button type="button" className="text-black bg-white border border-[#000000] hover:bg-white font-medium rounded-lg text-sm px-4 py-3" onClick={() => setCustomerModelStatus(!customerModelStatus)}>Add Customer</button>
                            }
                            {
                                privileges.includes('listAdd') &&
                                <button type="button" className="text-white bg-black border border-[#000000] hover:bg-black font-medium rounded-lg text-sm px-4 py-3" onClick={() => setListModelStatus(!listModelStatus)}>Create a list</button>
                            }
                        </div>
                        {location.pathname.includes('/list/by/influencer/') ?
                            <img alt='toggle-menu' src={humburger} className='cursor-pointer' onClick={() => setRightBar(true)} />
                            :
                            null
                        }
                    </div>


                </div>
            </nav >
            <Modal visible={customerModelStatus} width="420" height="536" effect="fadeInUp">
                <CompanyForm setCustomerModelStatus={setCustomerModelStatus} customerModelStatus={customerModelStatus} />
            </Modal >
            <Modal visible={listModelStatus} width="420" height="400" effect="fadeInUp">
                <ListForm setListModelStatus={setListModelStatus} listModelStatus={listModelStatus} />
            </Modal >

        </>
    )
}

export default Header
