import Modal from 'react-awesome-modal';
import React, { useContext, useState } from 'react'
import { CanvasContext } from '../context/MainContext';
import listSvg from '../assets/list.svg';
import closeSvg from '../assets/close.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import api from '../helpers/api';
import { encryption } from '../cipher/cipher';
import { date } from 'yup';


const EditContentModel = ({ editModel, setEditModel, handleEdit, name, setName, id }) => {
    let location = useLocation();
    let navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            setLoader(true)
            let path = (location.pathname.substring(5).charAt(0).toUpperCase() + location.pathname.substring(6));
            let url = '';

            if (path == 'City') {
                url = '/city/update'
            } else if (path == 'Interests') {
                url = '/interest/update'
            } else if (path == 'Categories') {
                url = '/category/update'
            } else if (path == 'Country') {
                url = '/country/update'
            };

            let encryptData = await encryption({ name: name, id: id, userId: localStorage.getItem("userId"), tz: "Asia/Calcutta" })

            let response = await api.post(url, { data: encryptData });

            if (response.status === 200) {
                let status = handleEdit();
                setEditModel(!editModel);
                setLoader(false)

            }
            else if (response.status == 603) {
                localStorage.clear();
                navigate('/');
            }
            else {
                setEditModel(!editModel);
                setLoader(false)
            }

        }
        catch (err) {
            console.log(err);
            setEditModel(!editModel);
            setLoader(false)
        }
    }

    return (
        <Modal visible={editModel} width="400" height="300" effect="fadeInUp" onClickAway={() => setEditModel(!editModel)}>
            <div className='pt-12'>
                <div className='flex justify-center items-center flex-col'>
                    <img src={listSvg} />
                    <div className='pt-2 font-bold'>Edit {location.pathname.slice(5)}</div>
                    <form className='w-[80%]' onSubmit={handleSubmit}>
                        <div className='mt-0'>
                            <label>Name</label>
                            <input type="text" name='listName' className='bg-[#FAFAFA] border mt-1 border-[#707070] text-black text-sm rounded-lg block w-full p-2.5 outline-none placeholder:text-[#707070]' value={name} onChange={(e) => setName(e.target.value)} placeholder="Type the name here..." />
                        </div>
                        <div className='mt-3 flex justify-center'>
                            {
                                loader ?
                                    <button type="submit" className="text-white h-[38px] w-[183px] bg-black border border-[#000000] hover:bg-black font-medium rounded-3xl text-sm">
                                        <ClipLoader color='white' />
                                    </button>
                                    :
                                    <button type="submit" className="text-white h-[38px] w-[183px] bg-black border border-[#000000] hover:bg-black font-medium rounded-3xl text-sm">
                                        Submit
                                    </button>
                            }

                        </div>
                    </form>
                </div >
                <img src={closeSvg} alt='close-btn' className='absolute top-[20px] left-[20px] cursor-pointer' onClick={() => {
                    setEditModel(!editModel)
                }
                } />
            </div >
        </Modal >
    )
}

export default EditContentModel;