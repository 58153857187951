import React from 'react'
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
    if (!(localStorage.getItem('token'))) {
        localStorage.clear();
        return <Navigate to="/login" replace />;
    }
    return children;
}

export default ProtectedRoutes;